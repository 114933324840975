// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mappedin-ad-part {
  position: relative;
}
.mappedin-ad-part img,
.mappedin-ad-part video {
  width: 100%;
  object-fit: cover;
  height: max(300px, 28.6vh);
  display: block;
}`, "",{"version":3,"sources":["webpack://./projects/clientside/src/app/components/widgets/mappedin-ad/mappedin-ad.component.scss"],"names":[],"mappings":"AAEA;EAEE,kBAAA;AAFF;AAGE;;EAEE,WAAA;EACA,iBAAA;EACA,0BAAA;EACA,cAAA;AADJ","sourcesContent":["@import '../../../../variable';\n\n.mappedin-ad-part {\n  // padding-top: min(390px, 36.11%);\n  position: relative;\n  img,\n  video {\n    width: 100%;\n    object-fit: cover;\n    height: max(300px, $image_height);\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
